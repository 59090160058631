import React, { useState } from 'react'
import classnames from 'classnames/bind'

import { Text } from 'components/typo'
import typo from 'components/typo/typography.module.scss'
import styles from './styles.module.scss'
import Link from 'next/link'
import Image from 'next/image'
import Button from 'components/shared/Button'
import delve from 'dlv'

const css = Object.assign({}, styles, typo)

const cx = classnames.bind(css)

const ButtonIconsSection = ({ buttonIcons }) => {
  return (
    <div className={cx(css.LinksSection)}>
      {buttonIcons.title && (
        <Text size="smaller" theme="grey-600" className={css.title}>
          {buttonIcons.title}
        </Text>
      )}
      <div className={css.linkContainer}>
        {buttonIcons.links && buttonIcons.links.length > 0
          ? buttonIcons.links.map((lowerLink, id) => (
              <div key={id} className={css.linkDiv}>
                {lowerLink.href && (
                  <Link className={css.links} href={lowerLink.href}>
                    {lowerLink.icons && lowerLink.icons.length > 0 && (
                      <Image
                        src={`${
                          process.env.API_URL +
                          lowerLink.icons[0]?.media?.data?.attributes?.url
                        }`}
                        alt={
                          lowerLink.icons?.[0]?.media?.data?.attributes
                            ?.alternativeText ||
                          lowerLink.icons?.[0]?.media?.data?.attributes?.name
                            ?.split('/')
                            .pop()
                        }
                        width={20}
                        className={css.linkIcon}
                        height={20}
                      />
                    )}
                  </Link>
                )}
              </div>
            ))
          : null}
      </div>
      {buttonIcons?.button && (
        <Button
          className={css.buttonLink}
          theme={'white-gray'}
          link={buttonIcons.button}
        >
          {delve(buttonIcons?.button, 'text')}
        </Button>
      )}
    </div>
  )
}

export default ButtonIconsSection
