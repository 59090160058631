import React, { useState } from 'react'
import classnames from 'classnames/bind'
import delve from 'dlv'

import { Text } from 'components/typo'
import LinkItem from './LinkItem'

import typo from 'components/typo/typography.module.scss'
import styles from './styles.module.scss'

const css = Object.assign({}, styles, typo)

const cx = classnames.bind(css)

const LinksSection = ({
  className,
  title,
  links,
  onLinkClick,
  sectionsLength,
  
}) => {
  const [hoverLinkId, setHoverLinkId] = useState(null)
  return (
    <div className={cx(css.LinksSection, className)}>
      {title && (
        <Text size="smaller" theme="grey-600" className={css.title}>
          {title}
        </Text>
      )}
      {links && links.length > 0 && (
        <ul
          className={cx(
            links.length > 4 && sectionsLength <= 2
              ? css.LinksSectionGrid
              : css.LinkSectionColumn
          )}
        >
          {links.map((link) => {
            const id = delve(link, 'id')
            const icons = delve(link, 'icons')

            return (
              <LinkItem
                key={id}
                id={id}
                hoverLinkId={hoverLinkId}
                className={css.link}
                icons={icons}
                link={link}
                onMouseEnter={() => setHoverLinkId(id)}
                onMouseLeave={() => setHoverLinkId(null)}
                onLinkClick={onLinkClick}
              />
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default LinksSection
